import {
  Button,
  Grid,
  MenuItem,
  Select,
  SliderThumb,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppointmentServiceInternal } from "../../services/appointment.service";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BUTTONS, APPOINTMENT_TYPE_ID } from "../../constants";
import { Card } from "react-bootstrap";
import sheduleAppointment from "../../assets/images/Appointment-logo.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isValid, parseISO } from "date-fns";
import { ProviderServiceInternal } from "../../services/provider.service";
import { CorrectionalFacilityServiceInternal } from "../../services/correctionFacilities.service";
import userStore from "../../store/store";
import { today } from "../Shared/Share";

interface SheduleAppointmentProps {
  changeStep: (data: any) => void;
  updateFormData: (data: any) => void;
  formData: any;
}
interface AppointmentOption {
  id: string;
  appointment_type: string;
}

interface BookedAppointment {
  appointmentDateTime: string;
  appointmenttypeid: number;
}

interface TimeSlot {
  value: string;
  label: string;
}
const currentDate = new Date();

const SheduleAppointment: React.FC<SheduleAppointmentProps> = (props) => {
  const user: any = userStore();
  const [dayAndTimeOff, setDayAndTimeOff] = useState<any[]>([]);
  const [options, setOptions] = useState<AppointmentOption[]>([]);
  const [bookedSlot, setBookedSlot] = useState<BookedAppointment[]>([]);
  const [holidayDates, SetHolidayDates] = useState<any>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<
    number | null
  >(null);
  const [availableWeekDays, setAvailableWeekDays] = useState<{
    [key: string]: string[];
  }>({});
  const [providerId, setProviderId] = useState<number | null>(null);
  const [providerName, setProviderName] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      appointmenttypeid: "",
      appointmentDate: new Date(),
      appointmentTime: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        appointmenttypeid: yup
          .string()
          .required("Appointment Type is required"),
        appointmentDate: yup.date().required("Appointment Date is required"),
        appointmentTime: yup.string().required("Appointment Time is required"),
      })
    ),
  });

  const isTimeSlotDisabled = (
    timeSlot: string,
    selectedAppointmentType: number | null,
    interval: number
  ): boolean => {
    const formattedStartDate = startDate?.toISOString();
    const matchingAppointments =
      bookedSlot &&
      bookedSlot.filter(
        (appointment) =>
          appointment.appointmentDateTime.split("T")[0] ===
          formattedStartDate?.split("T")[0]
      );

    const formattedStartDateForComparison = formattedStartDate
      ?.split("T")[0]
      .replace(/-/g, "/");

    if (dayAndTimeOff) {
      for (const timeOff of dayAndTimeOff) {
        const [timeOffDate, ...timeOffRange] = timeOff.split(" ");
        const timeRange = timeOffRange.join(" ");
        const formattedTimeOffDateForComparison = timeOffDate.replace(
          /-/g,
          "/"
        );

        if (
          formattedStartDateForComparison === formattedTimeOffDateForComparison
        ) {
          const [startTime, endOfTime] = timeRange.split("-");
          const formattedStartTime = formatTimeSlot(startTime);
          const formattedEndTime = formatTimeSlot(endOfTime);
          const formattedTimeSlotStart = formatTimeSlot(timeSlot);
          const existingEndTime = addMinutes(formattedTimeSlotStart, interval);

          if (
            (formattedTimeSlotStart >= formattedStartTime &&
              formattedTimeSlotStart < formattedEndTime) ||
            (existingEndTime > formattedStartTime &&
              existingEndTime <= formattedEndTime)
          ) {
            return true;
          }
        }
      }
    }
    if (matchingAppointments && matchingAppointments.length > 0) {
      const existingTimeSlots = matchingAppointments.map((appointment) => {
        const startTime = appointment.appointmentDateTime
          ?.split("T")[1]
          .split(".")[0];
        return [startTime];
      });

      const formattedTimeSlot = formatTimeSlot(timeSlot);
      const endTime = addMinutes(formattedTimeSlot, interval);
      for (let i = 0; i < existingTimeSlots.length; i++) {
        const existingStartTime: any = existingTimeSlots[i].toString();

        let currentInterval = interval;

        if (selectedAppointmentType === 1) {
          currentInterval =
            matchingAppointments[i].appointmenttypeid === 1 ? 60 : 30;
        } else {
          currentInterval =
            matchingAppointments[i].appointmenttypeid === 2 ? 30 : 60;
        }

        const existingEndTime = addMinutes(existingStartTime, currentInterval);

        if (
          (formattedTimeSlot >= existingStartTime &&
            formattedTimeSlot < existingEndTime) ||
          (endTime > existingStartTime && endTime <= existingEndTime) ||
          (formattedTimeSlot <= existingStartTime && endTime >= existingEndTime)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const addMinutes = (time: string, minutes: number): string => {
    const [hours, initialMinutes] = time.split(":");
    const totalMinutes =
      parseInt(hours, 10) * 60 + parseInt(initialMinutes, 10) + minutes;
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
    return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
      2,
      "0"
    )}:00`;
  };

  const formatTimeSlot = (timeSlot: string): string => {
    const [time, period] = timeSlot.split(" ");
    const [hours, minutes] = time.split(":");
    let formattedHours = hours;
    if (period.toLowerCase() === "pm" && hours !== "12") {
      formattedHours = String(Number(hours) + 12);
    } else if (period.toLowerCase() === "am" && hours === "12") {
      formattedHours = "00";
    }
    return `${formattedHours}:${minutes}:00`;
  };

  const generateTimeSlots = (): TimeSlot[] => {
    const timeSlots: TimeSlot[] = [];
    const interval = selectedAppointmentType === 1 ? 60 : 30;

    const dayOfWeek = startDate?.toLocaleDateString("en-US", {
      weekday: "long",
    });
    const isAvailable =
      availableWeekDays && availableWeekDays.hasOwnProperty(dayOfWeek);

    if (isAvailable) {
      const availableTimeRanges =
        availableWeekDays[dayOfWeek]?.flatMap((ranges) => ranges.split(",")) ||
        [];

      let previousEndTime = "";

      availableTimeRanges.forEach((timeRange) => {
        const [startTime, endTime] = timeRange
          .split("-")
          .map((time) => time.trim());

        let start = new Date(`2000-01-01T${convertTo24HourFormat(startTime)}`);
        const end = new Date(`2000-01-01T${convertTo24HourFormat(endTime)}`);

        if (
          previousEndTime &&
          start.getHours() >
            parseInt(convertTo24HourFormat(previousEndTime).split(":")[0], 10)
        ) {
          start = new Date(`2000-01-01T${convertTo24HourFormat(startTime)}`);
        }
        if (
          previousEndTime &&
          start <
            new Date(`2000-01-01T${convertTo24HourFormat(previousEndTime)}`)
        ) {
          return;
        }
        while (start < end) {
          // Check if the time slot is after the current time
          if (
            startDate.getDate() === today.getDate() &&
            start <= today &&
            start.getHours() <= today.getHours()
          ) {
            start.setTime(start.getTime() + interval * 60 * 1000);
            continue; // Skip this time slot
          }
          const timeString = start.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          const timeDiffMinutes =
            (end.getTime() - start.getTime()) / (60 * 1000);

          if (timeDiffMinutes < interval) {
            break;
          }
          if (
            !isTimeSlotDisabled(timeString, selectedAppointmentType, interval)
          ) {
            timeSlots.push({
              value: timeString,
              label: timeString,
            });
          }
          start.setTime(start.getTime() + interval * 60 * 1000);
        }
        previousEndTime = endTime;
      });
    }
    return timeSlots;
  };

  const convertTo24HourFormat = (timeString: string): string => {
    const [time, period] = timeString.split(" ");
    let [hours, minutes] = time.split(":");
    if (period.toLowerCase() === "pm" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }
    return `${hours.padStart(2, "0")}:${minutes}`;
  };

  const timeSlots = generateTimeSlots();

  const fetchDataFromApi = async () => {
    try {
      const response = await AppointmentServiceInternal.appointmenttype();
      if (response?.data.isSuccess) {
        // Sort the options alphabetically by appointment_type
        const sortedOptions = response.data.data.sort(
          (a: AppointmentOption, b: AppointmentOption) =>
            a.appointment_type.localeCompare(b.appointment_type)
        );
        setOptions(sortedOptions);
      }
      const apiResponse = await ProviderServiceInternal.assignProvider();
      if (apiResponse?.data.isSuccess) {
        if (apiResponse.data.data.holidays) {
          const holidays = apiResponse.data.data.holidays.map(
            (holiday: any) => {
              const [month, day, year] = holiday.split("/").map(Number);
              return new Date(year, month - 1, day, 0, 0, 0);
            }
          );
          SetHolidayDates(holidays);
        }
        setBookedSlot(apiResponse.data.data.bookedSlots);
        setDayAndTimeOff(apiResponse.data.data.dayAndTimeOff);
        setAvailableWeekDays(apiResponse.data.data.weeklyAvailability);
        setProviderId(apiResponse.data.data.userId);
        setProviderName(apiResponse.data.data.providerName);
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };

  const onAppointmentTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedType = event.target.value as number;
    setSelectedAppointmentType(selectedType);
  };

  const onSubmit = async (data: any) => {
    let isHeldFor = false;
    try {
      const response =
        await CorrectionalFacilityServiceInternal.getFacilityDetailByUserId(
          user.getUser().id
        );
      if (response?.data.isSuccess) {
        isHeldFor = response?.data.data.is_heldfor;
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
    const selectedAppointmentTypeId = Number(data.appointmenttypeid);
    const selectedAppointmentType = options.find(
      (option: any) => option.id === selectedAppointmentTypeId
    );
    const combinedData = {
      ...data,
      appointmenttypename: selectedAppointmentType?.appointment_type,
      providerId: providerId,
      providername: providerName,
      isHeldFor: isHeldFor,
    };
    props.updateFormData(combinedData);
    props.changeStep(1);
  };

  const handleChange = (date: Date) => {
    setStartDate(date);
    setValue("appointmentDate", date, { shouldValidate: true });
  };

  useEffect(() => {
    fetchDataFromApi();
    setValue("appointmenttypeid", props.formData.appointmenttypeid);
    setValue("appointmentDate", props.formData.appointmentDate ?? currentDate);
    setValue("appointmentTime", props.formData.appointmentTime);
    setSelectedAppointmentType(props.formData.appointmenttypeid);
    // }, []);
  }, [
    props.formData.appointmenttypeid,
    props.formData.appointmentDate,
    props.formData.appointmentTime,
  ]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="add-patient-form mt-3">
        <div className="d-flex justify-content-center">
          <Card style={{ maxWidth: "450px", width: "100%" }}>
            <Card.Body>
              <div className="referral-form">
                <div className="flex gap-3 mb-4">
                  <div className="form-title-icon">
                    <img alt="" src={sheduleAppointment} />
                  </div>
                  <h2 className="text-xl">Schedule an Appointment</h2>
                </div>

                <Grid item xs={12} md={6} className="pt-0">
                  <div className="form-label">
                    <label>
                      Appointment Type<span className="text-danger">*</span>
                    </label>
                  </div>
                  <Controller
                    name="appointmenttypeid"
                    control={control}
                    defaultValue={props.formData.appointmenttypeid}
                    render={({ field }) => (
                      <>
                        <Select
                          {...field}
                          className="form-control"
                          onChange={(e) => {
                            field.onChange(e);
                            onAppointmentTypeChange(
                              e as React.ChangeEvent<{ value: unknown }>
                            );
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          {options.map((option: any) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.appointment_type}
                              {option.id !==
                                APPOINTMENT_TYPE_ID.FIRSTTIMEBEINGSEEN &&
                                option.id !== APPOINTMENT_TYPE_ID.FOLLOWUP &&
                                ` (${option.appointment_type_time} minutes)`}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                  {errors.appointmenttypeid && (
                    <p className="text-red-600 text-sm">
                      {errors.appointmenttypeid.message}
                    </p>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div>
                      <div className="form-label">
                        <label>
                          Appointment Date<span className="text-danger">*</span>
                        </label>
                      </div>
                      <Controller
                        name="appointmentDate"
                        control={control}
                        render={({ field }) => (
                          <div
                            style={{
                              border: "1px solid grey",
                              borderRadius: "5px",
                              padding: "4px",
                            }}
                          >
                            <DatePicker
                              {...field}
                              className="custom-datepicker"
                              // selected={field.value}
                              selected={parseISODateTime(field.value)}
                              onChange={(date) => {
                                handleChange(date as Date);
                              }}
                              value={
                                field.value instanceof Date
                                  ? field.value.toLocaleDateString()
                                  : ""
                              }
                              minDate={currentDate}
                              showIcon
                              //value={startDate instanceof Date ? startDate.toLocaleDateString("en-GB") : undefined}
                              excludeDates={holidayDates}
                              filterDate={(date) => {
                                const dayOfWeek = date.toLocaleDateString(
                                  "en-US",
                                  { weekday: "long" }
                                );
                                const isAvailable =
                                  availableWeekDays &&
                                  availableWeekDays.hasOwnProperty(dayOfWeek);
                                return isAvailable;
                              }}
                            />
                          </div>
                        )}
                      />
                      {errors.appointmentDate && (
                        <p className="text-red-600 text-sm">
                          {errors.appointmentDate.message}
                        </p>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="form-label">
                      <label>
                        Appointment Time<span className="text-danger">*</span>
                      </label>
                    </div>
                    <Controller
                      name="appointmentTime"
                      control={control}
                      defaultValue={props.formData.appointmentTime}
                      render={({ field }) => (
                        <Select {...field} fullWidth>
                          {timeSlots.map((timeSlot) => (
                            <MenuItem
                              key={timeSlot.value}
                              value={timeSlot.value}
                            >
                              {timeSlot.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.appointmentTime && (
                      <p className="text-red-600 text-sm">
                        {errors.appointmentTime.message}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </div>

              <div className="row mb-1 pt-6">
                <div className="col-md-6">
                  <Button
                    type="button"
                    variant="contained"
                    className="w-full"
                    onClick={() => {
                      props.updateFormData(props.formData);
                      props.changeStep(-1);
                    }}
                  >
                    {BUTTONS.PREVIOUS}
                  </Button>
                </div>
                <div className="col-md-6">
                  <Button type="submit" variant="contained" className="w-full">
                    {BUTTONS.NEXT}
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </form>
    </>
  );
};
export default SheduleAppointment;

export const parseISODateTime = (v?: string | Date) => {
  if (!v) {
    return null;
  }

  if (v instanceof Date) {
    return v;
  }

  const date = parseISO(v);
  if (isValid(date)) {
    return date;
  }

  return null;
};

export class CorrectionalFacilityDetailDto {
    correctionalFacilityId?: number = -1;
    name: string = "";
    officerName?: string = "";
    email?: string = "";
    phoneNumber?: string = "";
    address?: string = "";
    city?: string = "";
    zipCode?: string = "";
    state?: string = "";
    ipList?: string = "";
    isHeldFor?:boolean =false;
    // Store as a string for API compatibility
    appointmentTypeIds?: string = "";

    // Temporary array for form selection (not sent to backend directly)
    appointmentTypeIdsList: number[] = [];
}

export interface CorrectionalFacilityListDto {
    id: number;
    name: string;
    email: string;
    phone_number: string;
    address: string;
    is_active: boolean;
    createdby: number;
    createdon: string;
    updatedby: number;
    updatedon: string;
    appointmentTypeIds?: string;
}
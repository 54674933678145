/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CorrectionalFacilityDetailDto } from "../../models/correctionalFacilityDtos";
import { IOption } from "../../models/select-option";
import { phoneNumberFormat, zipCodeFormat } from "../Shared/Share";
import { UsaStates, UsaStatesEnum } from "../../constants/UsaStatesEmun";
import { CorrectionalFacilityServiceInternal } from "../../services/correctionFacilities.service";
import { AppRoutings } from "../../enums/app-routing";
import CustomTextField from "../FormElements/CommonTextField";
import { CustomPatternInput } from "../FormElements/CommonPatternInput";
import { CustomAutoComplete } from "../FormElements/CommonAutoComplete/CustomAutoComplete";
import CustomCheckbox from "../FormElements/CommonCheckBox";
import CustomTextArea from "../FormElements/CommonTextArea";
import { AppointmentServiceInternal } from "../../services/appointment.service";
import MultiSelectAutocomplete from "../FormElements/CommonAutoComplete/MultiSelectAutocomplete";

interface Props {
  correctionalFacilityDetail: CorrectionalFacilityDetailDto;
}

const CorrectionFacilityForm: FC<Props> = ({ correctionalFacilityDetail }) => {
  const navigate = useNavigate();
  const [
    correctionalFacilityDetailContent,
    setCorrectionalFacilityDetailContent,
  ] = useState<CorrectionalFacilityDetailDto>(
    new CorrectionalFacilityDetailDto()
  );
  const [stateOption, setStateOption] = useState<IOption[]>([]);

  const validationSchema = yup.object().shape({
    email: yup.string(), //.email("Email not valid."),//.required("Email Address is Required"),
    officerName: yup.string(), //.matches(/^[a-zA-Z]+ [a-zA-Z]+$/, 'Officer Name should contain both first name and last name'),//.required('Officer Name is required'),
    name: yup.string().required("Correctional Facility Name is required"),
    address: yup.string(), //.required("Address is required"),
    phoneNumber: yup
      .string()
      //.required("Phone Number is required")
      .test(
        "format",
        "Please enter the correct Phone Number",
        function (value) {
          if (!value) return true; // Allow empty if not required
          const formatList = phoneNumberFormat.split("-");
          if (!formatList.length) return true;
          let isInValid = false;
          const tempList = value.replaceAll("_", "").split("-");
          if (tempList.length !== formatList.length) isInValid = true;
          else {
            for (let i = 0; i < tempList.length; i++) {
              if (tempList[i].length !== formatList[i].length) {
                isInValid = true;
                break;
              }
            }
          }
          return !isInValid;
        }
      ),
    city: yup.string(), //.required('City is required'),
    state: yup.string(), //.required('State is required'),
    zipCode: yup.string(),
    //.matches(zipCodeFormat, 'Invalid zip code'),
    //.required('Zip code is required'),
    ipList: yup
      .string()
      .test("ip-list", "Invalid IP address list", function (value) {
        if (!value) return true; // Allow empty value if not required
        const ipAddresses = value.split(",").map((ip) => ip.trim());
        // Check each IP address or range for validity
        return ipAddresses.every(
          (ip) =>
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
              ip
            ) ||
            /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|[1-2][0-9]|3[0-2])$/.test(
              ip
            )
        );
      }),
    appointmentTypeIdsList: yup
      .array()
      .of(
        yup.number().integer("Appointment type IDs must be integers").required()
      )
      .min(1, "At least one appointment type must be selected") // Ensure at least one type is chosen
      .required("Appointment types are required"),
  });

  useEffect(() => {
    setStateOption(
      Object.keys(UsaStatesEnum).map((state) => ({
        label: state,
        value: UsaStatesEnum[state as UsaStates],
      }))
    );
  }, []);

  const methods = useForm<CorrectionalFacilityDetailDto>({
    defaultValues: new CorrectionalFacilityDetailDto(),
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id");

  const handleSaveCorrectionalFacility = async (
    data: CorrectionalFacilityDetailDto
  ) => {
    try {
      const payload = {
        ...data,
        // Convert list to string for API compatibility
        appointmentTypeIds: (data.appointmentTypeIdsList || []).join(","),
      };

      if (
        !(data.appointmentTypeIdsList && data.appointmentTypeIdsList.length > 0)
      ) {
        toast.error("Please select at least one appointment type.");
        return;
      }

      if (data.correctionalFacilityId === -1) {
        const response: any =
          await CorrectionalFacilityServiceInternal.saveCorrectionalFacility(
            payload
          );

        if (response.data?.isSuccess) {
          toast.success("Correctional Facility Saved successfully.");
        } else {
          toast.error(response.data.message, { toastId: "error" });
          return;
        }
      } else {
        await CorrectionalFacilityServiceInternal.updateCorrectionalFacility(
          payload,
          String(data.correctionalFacilityId)
        );
        toast.success("Correctional Facility Updated successfully.");
        return;
      }
      navigate(AppRoutings.CorrectionalFacilityGrid);
    } catch (error) {
      console.error("Error during save operation:", error);
      toast.error(
        "Error while saving Correctional Facility. Please try again!"
      );
    }
  };

  const [appointmentTypes, setAppointmentTypes] = useState<IOption[]>([]);

  // Fetch appointment types
  useEffect(() => {
    async function fetchAppointmentTypes() {
      try {
        const response = await AppointmentServiceInternal.appointmenttype();
        if (response?.data.isSuccess) {
          setAppointmentTypes(
            response.data.data.map((type: any) => ({
              label: type.appointment_type,
              value: type.id,
            }))
          );
        } else {
          console.error(
            "Error fetching appointment types:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("Error fetching appointment types:", error);
      }
    }

    fetchAppointmentTypes();
  }, []);

  useEffect(() => {
    console.log("CorrectionalFacilityDetail:", correctionalFacilityDetail);

    if (
      correctionalFacilityDetail &&
      correctionalFacilityDetail.correctionalFacilityId !== -1
    ) {
      setCorrectionalFacilityDetailContent(correctionalFacilityDetail);
      methods.reset(correctionalFacilityDetail);

      const appointmentTypeIdsArray: number[] =
        correctionalFacilityDetail.appointmentTypeIds
          ?.split(",")
          .map((item) => Number(item.trim()))
          .filter((item) => !isNaN(item)) || [];

      methods.setValue("appointmentTypeIdsList", appointmentTypeIdsArray);
    } else {
      methods.reset(new CorrectionalFacilityDetailDto());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correctionalFacilityDetail]);

  return (
    <>
      <FormProvider {...methods}>
        <Grid className="d-flex justify-content-center">
          <Card style={{ width: "80%" }}>
            <Card.Body>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="subtitle2">
                    Correctional Facility Name :
                  </Typography>
                  <CustomTextField name="name" fullWidth />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="subtitle2">Email :</Typography>
                  <CustomTextField name="email" fullWidth />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="subtitle2">Officer Name :</Typography>
                  <CustomTextField name="officerName" fullWidth />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="subtitle2">Address :</Typography>
                  <CustomTextField name="address" fullWidth />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="subtitle2">City :</Typography>
                  <CustomTextField name="city" fullWidth />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="subtitle2">State :</Typography>
                  <CustomAutoComplete
                    name="state"
                    options={stateOption}
                    className="w-100"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="subtitle2">ZipCode :</Typography>
                  <CustomTextField name="zipCode" fullWidth />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="subtitle2">Phone Number :</Typography>
                  <CustomPatternInput
                    name="phoneNumber"
                    patternFormat={phoneNumberFormat.replace("_", "#")}
                    className="w-100"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="subtitle2">IP White list :</Typography>
                  <CustomTextArea name="ipList" fullWidth rows={3} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="subtitle2">
                    Appointment Types:
                  </Typography>
                  <MultiSelectAutocomplete
                    label=""
                    options={appointmentTypes}
                    value={appointmentTypes.filter(
                      (option) =>
                        (
                          correctionalFacilityDetailContent.appointmentTypeIds ||
                          ""
                        )
                          .split(",")
                          .map((id) => parseInt(id.trim(), 10))
                          .filter((id): id is number => !isNaN(id)) // Ensure only valid numbers are used
                          .includes(option.value as number) // Ensure option.value is treated as number
                    )}
                    onChange={(validAppointmentTypeIds: number[]) => {
                      methods.setValue(
                        "appointmentTypeIdsList",
                        validAppointmentTypeIds
                      );

                      methods.trigger("appointmentTypeIdsList"); // Trigger validation after selection

                      const csvString = validAppointmentTypeIds.join(",");

                      setCorrectionalFacilityDetailContent((prevContent) => ({
                        ...prevContent,
                        appointmentTypeIds: csvString,
                      }));
                    }}
                    isDisabled={false}
                    className="w-100"
                    fullWidth
                  />
                  <span style={{ color: "red" }}>
                    {methods.formState.errors?.appointmentTypeIdsList?.message}
                  </span>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                  <Typography
                    variant="subtitle2"
                    sx={{ marginTop: "7px", marginRight: "5px" }}
                  >
                    Allow To Specify Held For Facility:
                  </Typography>
                  <CustomCheckbox name="isheldFor" className="" />
                </Grid>
              </Grid>
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => navigate(AppRoutings.CorrectionalFacilityGrid)}
                >
                  Cancel
                </Button>
                <Box sx={{ marginLeft: "10px" }}>
                  <Button
                    variant="contained"
                    onClick={methods.handleSubmit((formData) => {
                      console.log("Button clicked, formData:", formData);
                      handleSaveCorrectionalFacility(formData);
                    })}
                  >
                    {correctionalFacilityDetailContent.correctionalFacilityId !==
                    -1
                      ? "Update"
                      : "Save"}
                  </Button>
                </Box>
              </Box>
            </Card.Body>
          </Card>
        </Grid>
      </FormProvider>
    </>
  );
};

export default CorrectionFacilityForm;

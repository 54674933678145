import React, { useEffect, useState } from "react";
import { FormControl, Autocomplete, TextField, MenuItem } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CustomAutoCompleteProps } from "./CustomAutoComplete.Props";
import { IOption } from "../../../models/select-option";

export const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
	name,
	options,
	label,
	isDisabled,
	hideErrorMessage = false,
	className = "",
	onBeforeChange,
	handleChange,
	fullWidth,
	multiple
}) => {
	const methods = useFormContext();
	const [value, setValue] = useState<IOption | null>(null);
	const selectedValue = methods.watch(name);

	useEffect(() => {
		const item = options.find((opt) => opt.value === selectedValue);
		setValue(item ? item : null);
	}, [selectedValue, options]);
	
	
	
	return (
		<FormControl className={className}>
			<Controller
				control={methods.control}
				name={name}
				render={({ field: { onChange, ref }, fieldState: { error } }) => (
					<Autocomplete
						fullWidth={fullWidth ? fullWidth : false}
						disablePortal
						options={options.sort(
							(a, b) => -(b.groupBy || "").localeCompare(a.groupBy || "")
						)}
						disabled={isDisabled}
						clearIcon={false}
						className={isDisabled ? "auto-complete-dropdown-disabled" : ""}
						value={value}
						style={{ width: "100%" }}
						groupBy={(option) => option.groupBy || ""}
						onChange={(
							event: React.SyntheticEvent<Element, Event>,
							newValue: IOption | null
						) => {
							onBeforeChange
								? onBeforeChange(newValue)
								: onChange(newValue ? newValue.value : null);
							if (!onBeforeChange && handleChange) handleChange(newValue);
						}}
						isOptionEqualToValue={(option: IOption, value) =>
							option.value === value.value
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label={label}
								inputRef={ref}
								error={!!error}
								autoComplete="off"
								helperText={error && !hideErrorMessage ? error.message : null}
								fullWidth
								className="auto-complete-dropdown"
							/>
						)}
						getOptionDisabled={(option) => !!option.disabledOptionTooltipText}
				
					// renderOption={(props, option) => (
					// 	<CustomTooltip
					// 		key={option.value}
					// 		tooltipStyle={{
					// 			border: "1px solid #034f8b",
					// 		}}
					// 		tooltipProps={{
					// 			title: option.disabledOptionTooltipText
					// 				? option.disabledOptionTooltipText
					// 				: "",
					// 			placement: "right",
					// 			children: (
					// 				<span>
					// 					<MenuItem
					// 						{...props}
					// 						disabled={!!option.disabledOptionTooltipText}
					// 					>
					// 						{option.label}
					// 					</MenuItem>
					// 				</span>
					// 			),
					// 		}}
					// 	/>
					// )}
					/>
				)}
			/>
		</FormControl>
	);
};

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BUTTONS } from "../../constants";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card } from "react-bootstrap";
import { AppointmentServiceInternal } from "../../services/appointment.service";
import inmateinformation from "../../assets/images/InmateInformation-logo.png";

interface InterfaceProps {
  changeStep: (data: any) => void;
  updateFormData: (data: any) => void;
  formData: any;
}
interface CountyOption {
  countyid: number;
  countyname: string;
}

// Function to format the Zip Code
const formatZipCode = (value: string) => {
  if (!value) return value;

  // Remove all non-digit characters
  const zip = value.replace(/[^\d]/g, "");

  // Format the string into Zip Code format: 12345 or 12345-6789
  if (zip.length > 5) {
    return `${zip.slice(0, 5)}-${zip.slice(5, 9)}`; // Add a dash after the 5th digit for ZIP+4
  }

  return zip; // If it's just 5 digits, return it as is
};

const handleZipCodeChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  onChange: (value: string) => void
) => {
  const formattedZipCode = formatZipCode(event.target.value);
  onChange(formattedZipCode); // Update the form with formatted Zip Code
};

const InmateInfoForm: React.FC<InterfaceProps> = (props: any) => {
  const [heldForCounty, setHeldForAnotherCounty] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState<CountyOption[]>([]);
  const [selectedAdminCounty, setSelectedAdminCounty] = useState<
    CountyOption[]
  >([]);

  const fetchDataFromApi = async () => {
    try {
      const response = await AppointmentServiceInternal.county();
      if (response?.data.isSuccess) {
        setSelectedCounty(response.data.data);
        setSelectedAdminCounty(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      inmatename: "",
      inmatedob: "",
      ZipCode: "",
      heldForAnotherCounty: heldForCounty,
      countyid: "",
      admincountyid: "",
      additionalinfo: "",
      medications: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        inmatename: yup.string().required("Inmate name is required"),
        inmatedob: yup.string().required("Date of birth is required"),
        ZipCode: yup.string().required("ZipCode is required"),
        heldForAnotherCounty: yup.boolean().required("This field is required"),
        countyid: yup.string().notRequired(),
        admincountyid: yup.string().notRequired(),
        additionalinfo: yup.string().notRequired(),
        medications: yup.string().notRequired(),
      })
    ),
  });

  const onSubmit = async (data: any) => {
    const selectedCountyId = Number(data.countyid);
    const HeldForCountyName = selectedCounty.find(
      (option: any) => option.countyid === selectedCountyId
    );

    const selectedAdminCountyId = Number(data.admincountyid);
    const AdminCountyName = selectedAdminCounty.find(
      (option: any) => option.countyid === selectedAdminCountyId
    );

    const combinedData = {
      ...data,
      heldForAnotherCounty: heldForCounty,
      heldforaothercountyname: heldForCounty
        ? HeldForCountyName?.countyname
        : "",
      admincountyname: AdminCountyName?.countyname,
    };
    props.updateFormData(combinedData);
    props.changeStep(1);
  };

  useEffect(() => {
    fetchDataFromApi();
    setValue("inmatename", props.formData.inmatename);
    setValue("inmatedob", props.formData.inmatedob);
    setValue("ZipCode", props.formData.ZipCode);
    setValue("countyid", props.formData.countyid);
    setValue("admincountyid", props.formData.admincountyid);
    setValue("medications", props.formData.medications);
    setValue("additionalinfo", props.formData.additionalinfo);
    setHeldForAnotherCounty(!!props.formData.isHeldFor);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="add-patient-form mt-3">
      <div className="d-flex justify-content-center">
        <Card style={{ maxWidth: "450px", width: "100%" }}>
          <Card.Body>
            <div className="patient-info">
              <div className="flex gap-3 mb-3">
                <div className="form-title-icon">
                  <div className="form-title-icon">
                    <img alt="" src={inmateinformation} />
                  </div>
                </div>
                <div>
                  <h2 className="text-xl font-bold mb-1">Inmate Information</h2>
                </div>
              </div>
              <Grid item xs={12} md={6}>
                <div>
                  <div className="form-label">
                    <label>
                      Inmate Name<span className="text-danger">*</span>
                    </label>
                  </div>
                  <Controller
                    name="inmatename"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        type="text"
                        className="m-auto"
                        fullWidth
                      />
                    )}
                  />
                  {errors.inmatename && (
                    <p className="text-red-600 text-sm">
                      {errors.inmatename.message}
                    </p>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <div className="form-label">
                    <label>
                      DOB<span className="text-danger">*</span>
                    </label>
                  </div>
                  <Controller
                    name="inmatedob"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        type="date"
                        className="m-auto"
                        fullWidth
                      />
                    )}
                  />
                  {errors.inmatedob && (
                    <p className="text-red-600 text-sm">
                      {errors.inmatedob.message}
                    </p>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <div className="form-label">
                    <label>
                      Inmate ZipCode<span className="text-danger">*</span>
                    </label>
                  </div>
                  <Controller
                    name="ZipCode"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        value={value || ""}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleZipCodeChange(event, onChange)}
                        placeholder="12345 or 12345-6789"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  {errors.ZipCode && (
                    <p className="text-red-600 text-sm">
                      {errors.ZipCode.message}
                    </p>
                  )}
                </div>
              </Grid>
              {props.formData.isHeldFor && (
                <Grid item xs={12} md={6}>
                  <div>
                    <div className="form-label radio-group">
                      <label>Held for another county:</label>
                    </div>

                    <Controller
                      name="countyid"
                      control={control}
                      defaultValue={props.formData.countyid}
                      render={({ field }) => (
                        <>
                          <Select {...field} className="form-control">
                            <MenuItem value="" disabled>
                              Select...
                            </MenuItem>
                            {selectedCounty.map((option: any) => (
                              <MenuItem
                                key={option.countyid}
                                value={option.countyid}
                              >
                                {option.countyname}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <div>
                  <div className="form-label">
                    <label>
                      List current medications, dosages and frequency taken (if
                      any):
                    </label>
                  </div>
                  <Controller
                    name="medications"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        type="text"
                        rows={5}
                        multiline
                        className="m-auto"
                        fullWidth
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <div className="form-label">
                    <label>
                      Any additional information that you believe for our
                      provider to know:
                    </label>
                  </div>
                  <Controller
                    name="additionalinfo"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        type="text"
                        rows={5}
                        multiline
                        className="m-auto"
                        fullWidth
                      />
                    )}
                  />
                </div>
              </Grid>
            </div>
            <div className="row mb-1 pt-6">
              {/* <div className="col-md-6">
                <Button type='button' variant="contained" className='w-full' onClick={() => {
                  props.updateFormData(props.formData);
                  props.changeStep(-1);
                }}>{BUTTONS.PREVIOUS}</Button>
              </div> */}
              <div className="col-md-6 m-auto">
                <Button type="submit" variant="contained" className="w-full">
                  {BUTTONS.NEXT}
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </form>
  );
};

export default InmateInfoForm;

import React, { FC } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IOption } from "../../../models/select-option";

export interface MultiSelectAutocompleteProps {
    options: IOption[];
    value: IOption[]; // Array of selected numeric values
    label?: string;
    onChange: (value: number[]) => void; // Callback to handle changes
    isDisabled?: boolean;
    error?: any;
    fullWidth?: boolean;
    className?: string;
    hideErrorMessage?: boolean;
    //isOptionEqualToValue?: (option: IOption, value: number) => boolean;
}

const MultiSelectAutocomplete: React.FC<MultiSelectAutocompleteProps> = ({
    options,
    value,
    label = "Select Options",
    onChange,
    isDisabled = false,
    error,
    fullWidth = false,
    className = "",
    hideErrorMessage = false,
}) => {
    return (
        <Autocomplete
            multiple
            options={options}
            value={value}  // Pass IOption[] directly
            onChange={(event, newValue) => {
                // Extract numeric values
                const numericValues = newValue.map((option) => option.value as number);
                
                // Call parent's onChange with extracted values
                onChange(numericValues);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    fullWidth={fullWidth}
                    error={!!error}
                    helperText={!hideErrorMessage && error?.message}
                    className={className}
                />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}  // Match by object
            disabled={isDisabled}
        />
    );
};



export default MultiSelectAutocomplete;


